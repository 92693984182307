@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    src: url('./assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Bold';
    font-style: normal;
    font-weight: normal;
    src: url('./assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}